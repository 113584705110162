<script setup lang="ts">
import Flex from "./Flex.vue";
import {Icon} from "@iconify/vue";
import {reactive} from "vue";
import {useRoute} from "vue-router";
const route = useRoute();
import {useConfigStore} from "../../stores/config.ts";
import {Route} from "../../types.ts";
import {useI18n} from "vue-i18n";
const {t} = useI18n()

const configStore = useConfigStore();
const routes = reactive<Route[]>([
  {name: t('home.menu.title1'), link: `/`},
  {name: t('home.menu.title2'), link: `/projs`},
  {name: t('home.menu.title3'), link: `/certs`},
  {name: t('home.menu.title4'), link: `/comn`},
])
</script>

<template>
<Flex   justify="between" items="center" class=" text-white text-lg sm:text-xl font-bold">
  <Flex justify="center" class="text-2xl mt-1">
    <RouterLink to="/" class="hover:brightness-150 transition-all ease-in duration-200 align-middle">
      <img src="../../assets/logo2.svg" alt="logo_img" class="size-12 inline">
      <span class=" pl-2">Styls.dev</span>
    </RouterLink>
  </Flex>
  <Flex class="max-lg:hidden" justify="center" items="center" gap-x="2" sm-gap-x="5">
    <RouterLink
                v-for="(rout,id) in routes"
                :key="id"
                :class="{'pt-1 hover:text-pink-300 transition-all ease-in duration-200  after:block after:h-0.5 hover:after:w-[100%] after:w-[0%] after:bg-pink-300 after:transition-all after:ease-in after:duration-200': route.path != '/'}"
                active-class="text-pink-300 underline underline-offset-8"
                :to="rout.link"
    >
      {{ t(`home.menu.title${id+1}`)}}
    </RouterLink>
  </Flex>
  <div @click="configStore.openMenu=!configStore.openMenu" class="max-lg:block hidden">
    <Icon icon="line-md:close-to-menu-alt-transition" class="hover:text-pink-300 size-8"/>
  </div>
</Flex>
</template>

<style scoped>

</style>