<script setup>
import {useConfigStore} from "./stores/config.ts";
import Header from "./components/Static/Header.vue";
import Flex from "./components/Static/Flex.vue";
import {ref, onMounted} from 'vue'
import {useI18n} from "vue-i18n";
import Menu from "./components/Static/Menu.vue";
import Footer from "./components/Static/Footer.vue";
import Toast from "./components/Static/Toast.vue";
const lang = ref(navigator.language ?? `en`);
const {locale} = useI18n();
const configStore = useConfigStore();

onMounted(()=> {
  (lang.value.split('-')[0] === `el`) ? locale.value = `el` : locale.value = `en`;
})
</script>

<template>
  <Flex :column="true" class="h-screen cursor-default"
  >
    <Header  class="bg-eggplant-950 z-50 fixed w-screen top-0 py-4 max-sm:px-4 max-md:px-16 max-lg:px-28 px-32"/>

    <Transition enter-from-class="scale-0" enter-active-class="transition-all duration-500 ease-in"
                leave-to-class="scale-0" leave-active-class="transition-all duration-500 ease-out"
                appear appear-active-class="transition-all duration-500 ease-in">
      <Toast v-if="configStore.showToast" class="self-center top-24 z-50 absolute bg-eggplant-800 text-white "/>
    </Transition>

    <Menu class="bg-eggplant-950 fixed top-20 lg:hidden h-screen z-10 transition duration-500 ease-in w-screen"
           :class="(configStore.openMenu) ? `translate-x-0` : `translate-x-full h-0`"
    />

    <div class="bg-transparent flex-grow mt-24  max-sm:px-4 max-lg:px-16 px-24 my-4"
    >
      <Suspense>
        <template #default>
          <RouterView />
        </template>
        <template #fallback>
          Loading...
        </template>
      </Suspense>
    </div>

    <Footer/>
  </Flex>
</template>

<style scoped>

</style>