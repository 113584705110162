<script setup lang="ts">

import Flex from "./Flex.vue";
import {reactive} from "vue";
import {useI18n} from "vue-i18n";
const {t, locale} = useI18n()
import {Route} from "../../types.ts";

const routes = reactive<Route[]>([
  {name: t('home.menu.title1'), link: `/`},
  {name: t('home.menu.title2'), link: `/projs`},
  {name: t('home.menu.title3'), link: `/certs`},
  {name: t('home.menu.title4'), link: `/comn`},
])
</script>

<template>
<div class="bg-eggplant-950 h-screen">
  <h2 class="font-bold text-center text-4xl" style="color: #8b5cf6">
    {{(locale === `el`) ? `Μενού` : `Menu`}}
  </h2>

  <Flex :column="true" items="center" class="w-screen mt-5 bg-egplant-950">
    <RouterLink
        v-for="(rout,id) in routes"
        :key="id"
        class="hover:text-pink-300 transition-all ease-in duration-200 w-screen py-3 text-center text-lg font-semibold after:block after:h-0.5 hover:after:w-[100%] after:w-[0%] after:bg-pink-300 after:transition-all after:ease-in after:duration-200"
        active-class="text-pink-300 bg-white "
        :to="rout.link"
    >
      {{ t(`home.menu.title${id+1}`)}}
    </RouterLink>
  </Flex>
</div>
</template>

<style scoped>

</style>