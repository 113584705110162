import {createI18n} from 'vue-i18n'
import el from './locales/el_GR.json'
import en from './locales/en_US.json'

const i18n = createI18n(
    {
        locale: `en`,
        fallbackLocale: `el`,
        legacy: false,
        globalInjection: true,
        messages: {en, el}
    }
)

export default i18n