// formkit.config.ts
import { defaultConfig } from '@formkit/vue'
import { rootClasses } from '../formkit.theme.ts'
import {genesisIcons} from "@formkit/icons";
import {createAutoAnimatePlugin, createFloatingLabelsPlugin} from "@formkit/addons";

export default defaultConfig({
    config: {
        rootClasses,
    },
    plugins: [
        createFloatingLabelsPlugin({
            useAsDefault: false, // defaults to false
        }),
        createAutoAnimatePlugin(
            {
                /* optional AutoAnimate config */
                // default:
                duration: 250,
                easing: 'ease-in-out'
            },
        )
    ],
    icons: {
        ...genesisIcons
    }
})