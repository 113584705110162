import { createApp } from 'vue'
import './style.css'
import { plugin} from '@formkit/vue'
import formkitConfig from "./formkit.config.ts";
import App from './App.vue'
import {createPinia} from "pinia";
import router from "./router";
import i18n from "./i18n";

const pinia = createPinia()

createApp(App).use(pinia).use(plugin, formkitConfig).use(i18n).use(router).mount('#app')
